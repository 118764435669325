/* eslint-disable react/prop-types */
import React from 'react';

import { BreakpointProvider } from '@next-common/breakpoint';
import { GtmProvider } from '@next-common/gtm-provider';

import { Provider, useSelector } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { hypernovaWrapperClassName } from '../utils/hypernovaWrapperClassName';

import { useStore, useApollo } from '@next-common/redux-apollo-helper';
import { MUIProvider } from '@next-common/mui';
import { ReduxApolloFragmentWrapperChildrenType } from '../types';

//@ts-expect-error: css
import s from './Wrapper.module.css';

// eslint-disable-next-line react/prop-types
export const Wrapper: React.FC = ({ children }) => {
  
  return (
    // use data-role="none" data-enhance="false" to disable jquery mobile "enhancing" of the html
    <div className={hypernovaWrapperClassName} data-role="none" data-enhance="false">
      <div className={s.background}>
        <BreakpointProvider>
          <GtmProvider>
            {children}
          </GtmProvider>
        </BreakpointProvider>
      </div>
    </div>
  );
};

//
// export const ReduxApolloFragmentWrapper: React.FC<RootFragmentType> = ({
//   Component,
//   initialReduxState,
//   initialApolloState,
//   moduleName,
//   ...componentPros
// }) => {
//   const store = useStore(initialReduxState, Component.reducers);
//   const apolloClient = useApollo(initialApolloState);
//
//   return (
//     <Provider store={store}>
//       <ApolloProvider client={apolloClient}>
//         <MUIProvider moduleName={moduleName}>
//           <Wrapper>
//             <Component {...componentPros} />
//           </Wrapper>
//         </MUIProvider>
//       </ApolloProvider>
//     </Provider>
//
//   );
// };

export const ReduxApolloFragmentWrapperChildren: React.FC<ReduxApolloFragmentWrapperChildrenType> = ({
  children,
  initialReduxState,
  initialApolloState,
  moduleName,
  reducers,
}) => {
  const store = useStore(initialReduxState, reducers);
  const apolloClient = useApollo(initialApolloState);

  const htmlFontSize = 10; // SSR default font Size, used in Canopy

  // us a css based dynamic css fontsize ajust as in css files transpiled by the postcss pluging where all rem values get ajusted see 'postcss-rem-to-rem-multiplier'
  // this is needed because different monolith pages have different <html> fontsize values. e.g. 13px, 12px, 16px.
  // a js in monolith is setting the css var --hyp-rem-multi earl in the page load, this react js is loaded later
  // css will look like this calc(1.2rem * var(--hyp-rem-multi))
  const pxToRem = (size: number): string => `calc(${size / htmlFontSize}rem * var(--hyp-rem-multi))`;

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <MUIProvider moduleName={moduleName} htmlFontSize={htmlFontSize} pixToRem={pxToRem} prefixwrapClassName={hypernovaWrapperClassName}>
          <Wrapper>{children}</Wrapper>
        </MUIProvider>
      </ApolloProvider>
    </Provider>
  );
};
